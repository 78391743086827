import { Controller } from "@hotwired/stimulus";
import hljs from "highlight.js";
import javascript from 'highlight.js/lib/languages/javascript';
import ruby from 'highlight.js/lib/languages/ruby';
import bash from 'highlight.js/lib/languages/bash';
import swift from 'highlight.js/lib/languages/swift';

export default class extends Controller {

  initialize() {
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('swift', swift);
    hljs.registerLanguage('ruby', ruby);
    hljs.registerLanguage('bash', bash);
  }

  connect() {
    hljs.highlightAll();
  }
}

